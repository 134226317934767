import React from 'react';
import IframeComponent from '../../components/iframe';

const IframePage = () => (
  <IframeComponent
    src='https://fragment-theta.vercel.app/'
    background='#ffffff'
    item={2}
  />
);

export default IframePage;
