import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import close from '../images/close.svg';
import * as iframeStyles from './css/worksItems.module.css';
import '../components/css/spinner.css';
import Spinner from 'react-bootstrap/Spinner';

const IframeComponent = (props) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    const iframe = document.querySelector('#confusionIframe');

    const getMessage = (message) => {
      if (message.data.type === 'resize') {
        iframe.style.height = `${message.data.height}px`;
      }
    };
    window.addEventListener('message', getMessage);
    return () => window.removeEventListener('message', getMessage);
  }, []);

  return (
    <div className={iframeStyles.iframeContainer} style={{ width: '100%' }}>
      <Link to='/works' state={{ item: props.item }}>
        <img
          className={iframeStyles.closeButton}
          src={close}
          alt='close button'
        />
      </Link>

      {loading && (
        <Spinner
          animation='border'
          variant='warning'
          className={iframeStyles.spinnerCenter}
        />
      )}

      <motion.iframe
        src={props.src}
        id='confusionIframe'
        layoutId={`item-${props.item}`}
        frameborder='0'
        scrolling='no'
        title='confusion'
        onLoad={handleLoad}
        style={{
          backgroundColor: `${props.background}`,
          filter: `${
            loading ? 'brightness(0.5)' : 'brightness(1) var(--grayscale)'
          }`,
        }}
      />
    </div>
  );
};
//#8e7aae
export default IframeComponent;
